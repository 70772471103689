import React from "react"
import Markdown from "react-markdown/with-html"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Seo from "components/Seo"
import Button from "components/Button"
import Layout from "components/Layout"

export const getPosts = graphql`
  query($slug: String!) {
    strapiPosts(slug: { eq: $slug }) {
      title
      caption
      category
      altText
      body
      slug
      keywords
      featureImage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 400, height: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

const Blog = ({ data }) => {
  const { strapiPosts } = data
  const backgroundFluidImageStack = [
    strapiPosts.featureImage.childImageSharp.fluid,
    `linear-gradient(to bottom, rgba(36, 39, 72, .9), rgba(32, 58, 95, 0.50))`,
  ].reverse()

  return (
    <Layout pageName="home">
      <Seo
        title={`${strapiPosts.title} | BalCon TAB Services`}
        description={`${strapiPosts.caption}`}
        keywords={strapiPosts.keywords}
        pathname={`/blog/${strapiPosts.slug}/`}
        image={strapiPosts.featureImage.childImageSharp.fixed}
      />
      <div className="u-mt-5">
        <BackgroundImage
          Tag="div"
          fluid={backgroundFluidImageStack}
          className="blog-post-banner"
        >
          <div className="u-abs-center u-center-text blog-post-banner__content">
            <h1 className="main-type main-type__heavy main-type__white main-type__header-primary">
              {strapiPosts.title}
            </h1>
            <p className="main-type main-type__white main-type__body u-mt-3">
              {strapiPosts.caption}
            </p>
            <AnchorLink to={`/blog/${strapiPosts.slug}#topOfArticle`}>
              <Button
                text="Read Article"
                color="green"
                primary={true}
                margin="u-mt-3"
              />
            </AnchorLink>
          </div>
        </BackgroundImage>

        <div className="blog-container" id="topOfArticle">
          <div className="blog-post">
            <Markdown
              source={strapiPosts.body}
              transformImageUri={uri =>
                uri.startsWith("http")
                  ? uri
                  : `${process.env.IMAGE_BASE_URL}${uri}`
              }
            />
          </div>
        </div>
      </div>
      {/* Anchor for Smooth Scroll */}
      <div id="contactForm"></div>
    </Layout>
  )
}

export default Blog
